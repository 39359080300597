import 'swiper/scss'; // eslint-disable-line
import 'swiper/scss/navigation'; // eslint-disable-line
import 'swiper/scss/pagination'; // eslint-disable-line
import 'swiper/scss/manipulation'; // eslint-disable-line
import 'swiper/scss/effect-fade'; // eslint-disable-line
import './swiper-small.scss'; // eslint-disable-line
import Swiper, { Navigation, Pagination, Manipulation, EffectFade } from 'swiper';

class Slider {
    constructor (element, options) {
        const settings = {
            autoHeight: false,
            autoplay: false,
            breakpoints: null,
            pauseOnHover: false,
            calculateHeight: true,
            centeredSlides: false,
            centeredSlidesBounds: false,
            counter: false,
            controller: null,
            coverflowEffect: null,
            crossfade: false,
            direction: 'horizontal',
            effect: 'slide',
            equalHeights: false,
            fractionText: '/',
            freeMode: false,
            freeModeSticky: false,
            fullscreen: false,
            fullscreenAttr: 'fullscreen',
            indicators: false,
            indicatorsInControls: true,
            indicatorsHover: false,
            initAttr: 'data-slider',
            initialSlide: 0,
            itemsAttr: 'slides',
            itemAttr: 'slide',
            lazyLoading: false,
            longSwipesRatio: 0.0025,
            loop: false,
            loopedSlides: null,
            loopAdditionalSlides: 0,
            mousewheel: false,
            paginationType: 'bullets',
            parallax: false,
            prevnext: true,
            renderBullet: (index, className) => {
                return '<span class="' + className + '">' + (index + 1) + '</span>';
            },
            renderCustom: null,
            renderProgressbar: null,
            renderFraction: (currentClass, totalClass) => {
                return '<span class="' + currentClass + '"></span>' +
                        '' + this.settings.fractionText + '' +
                        '<span class="' + totalClass + '"></span>';
            },
            resistanceRatio: 0.85,
            scrollbar: false,
            shortSwipes: true,
            speed: 500,
            slidesPerView: 1,
            slidesPerGroup: 1,
            slideToClickedSlide: false,
            threshold: 20,
            preloadImages: true,
            resizeTimeout: 150,
            wrapPrevnext: true,
            onBefore: null,
            onClickPrev: null,
            onClickNext: null,
            onFullscreen: null,
            onInit: null,
            onResize: null,
            onSlide: null,
            onSlideChange: null,
            onSlidePrev: null,
            onSlideStart: null,
            onSlideNext: null,
            onTransitionStart: null,
            onLazyLoaded: null,
            simulateTouch: true,
            spaceBetween: 0,
            updateOnWindowResize: true,
            watchSlidesProgress: false,
            thumbs: null
        };

        this.settings = Object.assign({}, settings, options);

        this.$slider = element;

        this.$slidesList = this.$slider.querySelector('[' + this.settings.initAttr + '="' + this.settings.itemsAttr + '"]');
        this.$slides = this.$slidesList.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.itemAttr + '"]');
        this.sliderDestroy = this.destroy;
        this.sliderSlideTo = this.sliderTo;
        this.resizeTimer = null;

        if (this.$slides.length > 1) {
            window.setTimeout(() => {
                this.initialize();
            }, 250);
        } else {
            this.$slider.classList.add('no-slider');
        }
    }

    initialize () {
        let autoplaySettings = false;
        let paginationType = this.settings.paginationType;
        let $fullscreenButton = null;
        let $scrollbar = null;
        let $prevButton = this.$slider.querySelector('[' + this.settings.initAttr + '="prev"]');
        let $nextButton = this.$slider.querySelector('[' + this.settings.initAttr + '="next"]');
        let $swiperPagination = null;
        let currentIndex = this.settings.initialSlide;

        const buildSlider = () => {
            const $slidesWrapper = document.createElement('div');
            const amountSlides = this.$slides.length;

            $slidesWrapper.classList.add('swiper-wrapper');

            for (let i = 0; i < this.$slides.length; i++) {
                this.$slides[i].classList.add('swiper-slide');
                this.$slides[i].setAttribute('data-swiper-slide-index', i);
                $slidesWrapper.appendChild(this.$slides[i]);
            }

            const $slidesContainer = document.createElement('div');
            $slidesContainer.classList.add('swiper-container');
            this.$slider.classList.add('swiper');
            this.$slider.classList.add('swiper-length-' + amountSlides);

            $slidesContainer.appendChild($slidesWrapper);

            this.$slidesList.appendChild($slidesContainer);

            return $slidesContainer;
        };

        this.$slidesContainer = buildSlider();

        this.$controls = document.createElement('div');
        this.$controls.classList.add('swiper-controls');
        this.$slidesList.appendChild(this.$controls);

        if (this.settings.scrollbar === true) {
            $scrollbar = document.createElement('div');
            $scrollbar.classList.add('swiper-scrollbar');

            this.$slidesContainer.appendChild($scrollbar);
        }

        if (this.settings.counter === true) {
            paginationType = 'fraction';
        }

        if ($prevButton === null && $nextButton === null && this.settings.prevnext === true) {
            $prevButton = document.createElement('div');
            $prevButton.classList.add('swiper-button-prev');
            $prevButton.innerHTML = '<i>prev</i>';
            $prevButton.setAttribute(this.settings.initAttr, 'prev');

            $nextButton = document.createElement('div');
            $nextButton.classList.add('swiper-button-next');
            $nextButton.innerHTML = '<i>next</i>';
            $nextButton.setAttribute(this.settings.initAttr, 'next');

            if (this.settings.wrapPrevnext === true) {
                const $prevNextNavigation = document.createElement('div');
                $prevNextNavigation.classList.add('swiper-navigation');

                $prevNextNavigation.appendChild($prevButton);
                $prevNextNavigation.appendChild($nextButton);

                this.$controls.appendChild($prevNextNavigation);
            } else {
                this.$slidesList.appendChild($prevButton);
                this.$slidesList.appendChild($nextButton);
            }

            $prevButton.addEventListener('click', () => {
                if (typeof this.settings.onClickPrev === 'function') {
                    this.settings.onClickPrev(this.slider.activeIndex - 1);
                }
            });

            $nextButton.addEventListener('click', () => {
                if (typeof this.settings.onClickNext === 'function') {
                    this.settings.onClickNext(this.slider.activeIndex + 1);
                }
            });
        }

        if (this.settings.fullscreen === true) {
            $fullscreenButton = document.createElement('div');
            $fullscreenButton.classList.add('swiper-button-fullscreen');
            $fullscreenButton.innerHTML = '<i>Fullscreen</i>';
            $fullscreenButton.setAttribute(this.settings.initAttr, 'fullscreen');

            if (this.settings.wrapPrevnext === true) {
                this.$controls.appendChild($fullscreenButton);

                if (this.settings.prevnext === true) {
                    this.$controls.appendChild($prevButton);
                    this.$controls.appendChild($nextButton);
                }
            } else {
                this.$slidesList.appendChild($fullscreenButton);

                if (this.settings.prevnext === true) {
                    this.$slidesList.appendChild($prevButton);
                    this.$slidesList.appendChild($nextButton);
                }
            }
        }

        if (this.settings.indicators === true || this.settings.counter === true) {
            if (this.settings.pagination.el) {
                $swiperPagination = this.settings.pagination.el;
            } else {
                $swiperPagination = document.createElement('div');
            }

            $swiperPagination.classList.add('swiper-pagination');

            if (!this.settings.pagination.el && this.settings.paginationType !== 'progressbar') {
                if (this.settings.indicatorsInControls === true && this.settings.wrapPrevnext === true) {
                    this.$controls.appendChild($swiperPagination);
                } else {
                    this.$slidesList.appendChild($swiperPagination);
                }
            }
        }

        if (this.settings.autoplay !== false) {
            autoplaySettings = {
                delay: this.settings.autoplay,
                disableOnInteraction: this.settings.pauseOnHover
            };
        }

        this.$prevButton = $prevButton;
        this.$nextButton = $nextButton;

        this.slider = new Swiper(this.$slidesContainer, {
            modules: [Navigation, Pagination, Manipulation, EffectFade],
            autoHeight: this.settings.autoHeight,
            autoplay: autoplaySettings,
            breakpoints: this.settings.breakpoints,
            centeredSlides: this.settings.centeredSlides,
            centeredSlidesBounds: this.settings.centeredSlidesBounds,
            direction: this.settings.direction,
            effect: this.settings.effect,
            spaceBetween: this.settings.spaceBetween,
            coverflowEffect: this.settings.coverflowEffect,
            fadeEffect: {
                crossFade: this.settings.crossfade
            },
            freeMode: this.settings.freeMode,
            freeModeSticky: this.settings.freeModeSticky,
            initialSlide: this.settings.initialSlide,
            lazy: this.settings.lazyLoading,
            longSwipesRatio: this.settings.longSwipesRatio,
            loop: this.settings.loop,
            loopedSlides: this.settings.loopedSlides,
            loopAdditionalSlides: this.settings.loopAdditionalSlides,
            mousewheel: this.settings.mousewheel,
            navigation: {
                nextEl: $nextButton,
                prevEl: $prevButton
            },
            normalizeSlideIndex: true,
            observer: true,
            pagination: {
                clickable: true,
                el: $swiperPagination,
                type: paginationType,
                renderCustom: this.settings.renderCustom,
                renderBullet: this.settings.renderBullet,
                renderFraction: this.settings.renderFraction,
                renderProgressbar: this.settings.renderProgressbar
            },
            preloadImages: this.settings.preloadImages,
            parallax: this.settings.parallax,
            resistanceRatio: this.settings.resistanceRatio,
            roundLengths: true,
            scrollbar: {
                el: $scrollbar,
                draggable: true
            },
            simulateTouch: this.settings.simulateTouch,
            shortSwipes: this.settings.shortSwipes,
            slideToClickedSlide: this.settings.slideToClickedSlide,
            slidesPerView: this.settings.slidesPerView,
            slidesPerGroup: this.settings.slidesPerGroup,
            speed: this.settings.speed,
            threshold: this.settings.threshold,
            updateOnWindowResize: this.settings.updateOnWindowResize,
            watchSlidesProgress: this.settings.watchSlidesProgress,
            thumbs: this.settings.thumbs,
            on: {
                init: () => {
                    if (this.settings.calculateHeight === true) {
                        this.calculateContainerHeight();
                    }

                    this.$slidesList.classList.add('swiper-items');

                    if (this.settings.fullscreen === true) {
                        $fullscreenButton.addEventListener('click', () => {
                            this.settings.onFullscreen(currentIndex);
                        });
                    }

                    if (this.settings.indicators === true && this.settings.indicatorsHover === true) {
                        const bulletInterval = window.setInterval(() => {
                            const $bullets = $swiperPagination.querySelectorAll('span');

                            if ($bullets.length > 0) {
                                clearInterval(bulletInterval);

                                for (let b = 0; b < $bullets.length; b++) {
                                    const $bullet = $bullets[b];

                                    $bullet.addEventListener('mouseenter', () => {
                                        $bullet.click();
                                    });
                                }
                            }
                        }, 100);
                    }

                    if (typeof this.settings.onInit === 'function') {
                        this.settings.onInit(this, this.$slider.querySelector('.swiper-slide-active'), this.settings.initialSlide);
                    }
                },
                slideChangeTransitionEnd: () => {
                    const $current = this.$slider.querySelector('.swiper-slide-active');

                    currentIndex = parseInt($current.getAttribute('data-swiper-slide-index'));

                    if (typeof this.settings.onSlide === 'function') {
                        this.settings.onSlide(this.slider, $current, currentIndex);
                    }
                },
                slideChange: () => {
                    if (typeof this.slider !== 'undefined') {
                        currentIndex = this.slider.activeIndex;

                        const $current = this.slider.slides[currentIndex];

                        if (typeof this.settings.onSlideChange === 'function') {
                            this.settings.onSlideChange(this.$slider, this.slider, $current, currentIndex);
                        }
                    }
                },
                lazyImageReady: () => {
                    if (typeof this.settings.onLazyLoaded === 'function') {
                        this.settings.onLazyLoaded(this.$slider, this.$slider.querySelector('.swiper-slide-active'));
                    }
                },
                slideChangeTransitionStart: () => {
                    const $current = this.$slider.querySelector('.swiper-slide-active');
                    if (typeof this.settings.onSlideStart === 'function') {
                        this.settings.onSlideStart(this, this.$slider, this.slider, $current);
                    }
                },
                slidePrevTransitionStart: (swiper) => {
                    if (typeof this.settings.onSlidePrev === 'function') {
                        this.settings.onSlidePrev(swiper);
                    }
                },
                slideNextTransitionStart: (swiper) => {
                    if (typeof this.settings.onSlideNext === 'function') {
                        this.settings.onSlideNext(swiper);
                    }
                },
                transitionStart: (swiper) => {
                    const activeIndex = swiper.activeIndex;
                    if (typeof this.settings.onTransitionStart === 'function') {
                        this.settings.onTransitionStart(activeIndex);
                    }
                },
                resize: () => {
                    if (this.settings.calculateHeight === true) {
                        this.calculateContainerHeight(() => {
                            this.slider.update();
                        });
                    }
                    if (typeof this.settings.onResize === 'function') {
                        this.settings.onResize(this.$slider);
                    }
                }
            }
        });
    }

    calculateContainerHeight (callback) {
        let maxHeight = 0;
        this.$slidesList.style.height = 'auto';

        if (this.settings.equalHeights === true) {
            for (let i = 0; i < this.$slides.length; i++) {
                const $slide = this.$slides[i];
                $slide.style.height = 'auto';
            }
        }

        for (let i = 0; i < this.$slides.length; i++) {
            const $slide = this.$slides[i];

            if ($slide.offsetHeight > maxHeight) {
                maxHeight = $slide.offsetHeight;
            }
        }

        this.$slidesList.style.height = maxHeight + 'px';

        if (this.settings.equalHeights === true) {
            for (let i = 0; i < this.$slides.length; i++) {
                const $slide = this.$slides[i];
                $slide.style.height = maxHeight + 'px';
                if (i === this.$slides.length - 1 && typeof callback === 'function') {
                    callback();
                }
            }
        }
    }

    sliderPrev () {
        this.slider.slidePrev();
    }

    sliderNext () {
        this.slider.slideNext();
    }

    sliderTo (i) {
        this.slider.slideTo(i);
    }

    update () {
        this.slider.update();
    }

    destroy () {
        if (typeof this.slider !== 'undefined' && this.slider !== null) {
            clearTimeout(this.resizeTimer);

            if (this.$controls !== null) {
                this.$controls.parentNode.removeChild(this.$controls);
            }

            this.slider.destroy();

            for (let i = 0; i < this.$slides.length; i++) {
                const $currentSlide = this.$slides[i];
                $currentSlide.classList.remove('swiper-slide');
                this.$slidesList.appendChild($currentSlide);
            }

            this.$slidesList.classList.remove('swiper-items');
            this.$slider.classList.remove('swiper');
            this.$slider.classList.remove('swiper-length-' + this.$slides.length);

            // this.$slider.appendChild(this.$slidesList);
            this.$slidesContainer.parentNode.removeChild(this.$slidesContainer);
            this.$slidesList.style.height = 'auto';
        }
    }
}

export { Slider };
